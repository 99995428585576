import Image from "next/image";


interface ErrorProps {
  statusCode: number;
  errorMessage: string;
}

const ErrorComponent = (props: ErrorProps) => {

  return (
    <div className="relative w-full">
      <Image
        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/error-desktop.jpg"}
        alt="404 image"
        width={1600}
        height={800}
        quality={100}
        className="hidden w-full md:block"
      />
      <Image
        src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/error-mobile.jpg"}
        alt="404 image"
        width={900}
        height={1600}
        quality={100}
        className="w-full md:hidden"
      />
      <div className="absolute top-[35px] md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2 text-center font-poppins">
        <h1 className="text-center text-[96px] md:text-[128px] font-medium text-white">{props.statusCode}</h1>
        <p className="text-sm font-medium text-center text-white md:text-2xl">{props.errorMessage}</p>
        <button
          className="py-2 px-4 bg-mooimom text-white text-sm md:text-base font-bold rounded w-[300px] md:w-[384px] mt-6"
          onClick={() => window.location.href = "/"}
        >
          Quay lại trang chủ
        </button>
      </div>
    </div>
  )
}

export default ErrorComponent;
